import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import Thermostat from "@mui/icons-material/Thermostat";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import { useTheme, IconButton, Modal } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FirstComponent from "../../Components/DatePicker";
import PredictTable from "../../Components/PredictTable";
import Linetest from "../../Components/Linetest";
import instance from "../../middleware";
import "../../Components/multi.css";
import IntraLine from "../../Components/IntraLine";
function IntraDay() {
  const [IntraLoad] = useState([
    295, 290, 284, 280, 286, 285, 280, 276, 271, 265, 262, 260, 257, 268.4,
    269.5, 265.5, 266.7, 265.71, 271.51, 272.24, 276.32,279.66,282.34,284.42,283.36
  ]);
  const [additionalData] = useState([
    295, 292, 287, 283, 286, 286, 283, 277, 275, 269, 265, 259, 266, 271.47,
    270.6, 269.3, 268.34, 268.38, 269.22, 269.89, 272.42, 275.97, 280.77,
    286.15, 292.36, 299.84, 307.44, 316.69, 327.83, 342.8, 358.02, 374.88,
    391.34, 407.46, 420.59, 432.13, 441.78, 453.18, 460.78, 468.17, 474.06,
    480.68, 482.35, 484.67, 486.07, 487.96, 485.88, 486.03, 485.5, 483.37,
    480.99, 479.71, 478.13, 476.89, 477.77, 479.51, 483.89, 485.57, 485.95,
    487.29, 486.77, 483.91, 481.24, 480.85, 477.33, 475.09, 472.1, 468.61,
    464.16, 458.5, 452.75, 446.35, 440.56, 433.78, 428.91, 421.26, 415.74,
    409.23, 402.66, 396.45, 390.29, 384.27, 379.1, 375.15, 369.92, 366.11,
    362.87, 359.37, 354.53, 349.37, 345.7, 340.33, 334.94, 331.53, 325.61,324.42
  ]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 700,
    // bgcolor: "background.paper",
    backgroundColor: colors.primary.main,
    boxShadow: 24,
    p: 4,
  };
  const [selectedDate, setSelectedDate] = useState();
  const [dayType, setDayType] = useState();
  const [data, setData] = useState({ predictLoad: null });
  const [chartData, setChartData] = useState(null);
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const [isTempModalOpen, setIsTempModalOpen] = useState(false);
  const [isHumidityModalOpen, setIsHumidityModalOpen] = useState(false);
  const [maeData, setMaeData] = useState(0);
  const [maxError, setMaxError] = useState(null);
  const [minError, setMinError] = useState(null);
  const [mape, setMape] = useState(null);

  const [averageValues, setAverageValues] = useState({
    averageTemperature: 0,
    averageHumidity: 0,
    averageLoad: 0,
    maxLoad: 0,
    minLoad: 0,
  });

  const toggleLoadModal = () => {
    setIsLoadModalOpen(!isLoadModalOpen);
  };

  const toggleTempModal = () => {
    setIsTempModalOpen(!isTempModalOpen);
  };

  const toggleHumidityModal = () => {
    setIsHumidityModalOpen(!isHumidityModalOpen);
  };

  const handleAverageValuesChange = (values) => {
    setAverageValues({
      averageTemperature: parseFloat(values.averageTemperature.toFixed(2)),
      averageHumidity: parseFloat(values.averageHumidity.toFixed(2)),
      averageLoad: parseFloat(values.averageLoad.toFixed(2)),
      maxLoad: parseFloat(values.maxLoad.toFixed(2)),
      minLoad: parseFloat(values.minLoad.toFixed(2)),
    });
  };

  const handleDateChange = (changes) => {
    let newDate = changes[0];
    let newDayType = changes[1];
    const startDate = dayjs("2020-01-01");
    const endDate = dayjs().add(1, "day");
    const selected = dayjs(newDate);
    if (selected.isBefore(startDate) || selected.isAfter(endDate)) {
      // console.log("Selected date is out of range");
      return;
    }
    setSelectedDate(newDate);
    setDayType(newDayType);
  };

  const handleDayTypeChange = (newDayType) => {
    setDayType(newDayType);
  };

  //   try {
  //     const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
  //     console.log(selectedDate);
  //     const token = localStorage.getItem('authority2');
  //     const response = await axios.post(
  //       "http://127.0.0.1:8000/getMAERange",
  //       `FROM=${formattedDate}&TO=${formattedDate}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           "Authorization": `Bearer ${token}`
  //         },
  //       }
  //     );
  //     if (response.data && response.data.maes) {
  //       setMaeData(response.data.maes[0]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching MAE range:", error);
  //   }
  // };

  const fetchChartData = async (selectedDate, dayType) => {
    if (selectedDate && dayType) {
      try {
        const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
        const startAnnDate = "2024-08-08";
        const apiEndpoint = selectedDate.isAfter(startAnnDate)
          ? "/predictLoad_ANN"
          : "/predictLoad";
        const predictLoadResponse = await instance.post(
          apiEndpoint,
          `DATE=${formattedDate}&SPECIALDAY=${dayType}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        const jsonData = predictLoadResponse.data.blocks;
        setData({ predictLoad: jsonData });
        setChartData(jsonData);
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (selectedDate && dayType) {
      fetchChartData(selectedDate, dayType);
    }
  }, [selectedDate, dayType]);
  return (
    <div
      style={
        {
          // marginLeft:'0.313rem'
        }
      }
    >
      <Grid lg={12} sm={12} md={12} xs={12} sx={{ mt: "1.875rem" }}>
        <Grid sm={12} md={12} lg={12} xs={12} sx={{ width: "98%" }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              borderRadius: "20px",
              // width: "99%",
              alignItems: "center",
              // marginTop: "7.2rem",
              position: "relative",
              top: 0,
              // marginLeft: "0.313rem",
              zIndex: 0,
              height: "5rem",
              backgroundColor: colors.primary.main,
              boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
            }}
          >
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: "0rem",
                width: "100%",
              }}
            >
              <Grid item sx={{}} xs={6} sm={6} md={1.7} lg={1.7}>
                <Typography
                  sx={{
                    marginTop: "0.313rem",
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    marginLeft: "1.3rem",
                    fontFamily: "Poppins,sans-serif",
                    color: colors.primary.dark,
                    width: "100%",
                  }}
                >
                  Including Today
                </Typography>
              </Grid>

              <Grid xs={10} sm={10} md={10} lg={10.2}>
                <FirstComponent
                  onDateChange={handleDateChange}
                  onDayTypeChange={handleDayTypeChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          sm={12}
          md={12}
          xs={12}
          sx={{
            marginTop: "2rem",
            width: "100%",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              sx={{
                backgroundColor: colors.primary.main,
                display: "flex",
                flexDirection: "column",
                borderRadius: "1.25rem",
                // width: "64rem",
                justifyContent: "flex-start",
                // alignItems: "center,
                position: "relative",
                top: 0,
                height: "30rem",
                marginRight: "1.5rem",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <Typography
                    display="flex"
                    variant="h2"
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "600",
                      fontFamily: "Poppins,sans-serif",
                      color: colors.grey[900],
                      display: "flex",
                      marginTop: "2rem",
                      marginLeft: "2rem",
                    }}
                  >
                    Load
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={toggleLoadModal}
                    sx={{
                      // position: "absolute",
                      // top: "1rem",
                      // right: "1rem",
                      marginRight: "16px",
                      marginTop: "16px",
                    }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <IntraLine
                selectedDate={selectedDate}
                dayType={dayType}
                metric="wdLoad"
                IntraLoad={IntraLoad}
                additionalData={additionalData}
                margins={{ top: 10, right: 30, bottom: 120, left: 70 }}
                yDomain={[0, 100]}
                legend="Load"
                pointColor=" rgba(0,0,0,0)"
                actualData={data.getData}
                predictedData={data.predictLoad}
                chartData={chartData}
                expandedView
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                pointBorderColor={{ from: "serieColor" }}
                color1={[colors.linechart[600], "#0000ff", "green"]}
                containercolor={colors.linechart[400]}
              />
            </Grid>
            <Box>
              <Modal
                open={isLoadModalOpen}
                onClose={toggleLoadModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                    backdropFilter: "blur(2px)",
                  },
                }}
              >
                <Box sx={modalStyle}>
                  <IntraLine
                    selectedDate={selectedDate}
                    dayType={dayType}
                    IntraLoad={IntraLoad}
                    additionalData={additionalData}
                    metric="wdLoad"
                    margins={{ top: 80, right: 0, bottom: 70, left: 50 }}
                    yDomain={[0, 70]}
                    legend="Load"
                    pointColor=" rgba(0,0,0,0)"
                    pointFaceColor="rgba(255,255,255)"
                    pointSize={2}
                    lineWidth={1}
                    pointWidth={1}
                    pointBorderColor={{ from: "serieColor" }}
                    actualData={data.getData}
                    predictedData={data.predictLoad}
                    chartData={chartData}
                    color1={colors.linechart[600]}
                    containercolor={colors.linechart[400]}
                    expandedView
                  />

                  <IconButton
                    onClick={toggleLoadModal}
                    sx={{
                      position: "absolute",
                      top: "0.625rem",
                      right: "0.625rem",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Modal>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={2}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ marginTop: "1.875rem", width: "99%" }}
        >
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{}}>
            <Grid
              sx={{
                backgroundColor: colors.primary.main,
                display: "flex",
                flexDirection: "column",
                borderRadius: "1.25rem",

                justifyContent: "flex-start",
                // alignItems: "center",
                position: "relative",
                top: 0,
                height: "21.875rem",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <Typography
                    display="flex"
                    variant="h2"
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "600",
                      marginTop: "1.75rem",
                      fontFamily: "Poppins,sans-serif",
                      color: colors.grey[900],
                      display: "flex",
                      // marginBottom: "1.5rem",
                      marginTop: "2rem",
                      marginLeft: "1.875rem",
                    }}
                  >
                    Temperature
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={toggleTempModal}
                    sx={{
                      // position: "absolute",
                      // top: "1rem",
                      // right: "1rem",
                      marginTop: "15px",
                      marginRight: "15px",
                    }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Linetest
                selectedDate={selectedDate}
                dayType={dayType}
                metric="temp"
                actualData={data.getData}
                predictedData={data.predictLoad}
                margins={{ top: 5, right: 30, bottom: 120, left: 70 }}
                legend="Temperature"
                pointColor=" rgba(0,0,0,0)"
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                pointBorderColor={{ from: "serieColor" }}
                color1={[colors.linechart[200]]}
                containercolor={colors.linechart[200]}
                // chartData={chartData}
              />
            </Grid>
            <Box>
              <Modal
                open={isTempModalOpen}
                onClose={toggleTempModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                    backdropFilter: "blur(2px)",
                  },
                }}
              >
                <Box sx={modalStyle}>
                  <Linetest
                    selectedDate={selectedDate}
                    dayType={dayType}
                    metric="temp"
                    actualData={data.getData}
                    predictedData={data.predictLoad}
                    margins={{ top: 80, right: 0, bottom: 70, left: 50 }}
                    legend="Temperature"
                    color1={[colors.linechart[200]]}
                    containercolor={colors.linechart[200]}
                    pointColor=" rgba(0,0,0,0)"
                    pointFaceColor="rgba(255,255,255)"
                    pointSize={2}
                    lineWidth={1}
                    pointWidth={1}
                    pointBorderColor={{ from: "serieColor" }}
                    // chartData={chartData}
                  />
                  <IconButton
                    onClick={toggleTempModal}
                    sx={{
                      position: "absolute",
                      top: "0.625rem",
                      right: "0.625rem",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Modal>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{}}>
            <Grid
              sx={{
                backgroundColor: colors.primary.main,
                display: "flex",
                flexDirection: "column",
                borderRadius: "1.25rem",

                justifyContent: "flex-start",
                // alignItems: "center",
                position: "relative",
                top: 0,
                height: "21.875rem",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <Typography
                    display="flex"
                    variant="h2"
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "600",
                      marginTop: "1.75rem",
                      fontFamily: "Poppins,sans-serif",
                      color: colors.grey[900],
                      display: "flex",
                      marginTop: "2rem",
                      marginLeft: "1.875rem",
                    }}
                  >
                    Humidity
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={toggleHumidityModal}
                    sx={{
                      // position: "absolute",
                      // top: "1rem",
                      // right: "1rem",
                      marginRight: "15px",
                      marginTop: "15px",
                    }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Linetest
                selectedDate={selectedDate}
                dayType={dayType}
                metric="rh"
                yDomain={[0, 70]}
                margins={{ top: 4, right: 30, bottom: 120, left: 70 }}
                actualData={data.getData}
                predictedData={data.predictLoad}
                legend="Humidity"
                color1={[colors.linechart[300]]}
                containercolor={colors.linechart[300]}
                pointColor=" rgba(0,0,0,0)"
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                pointBorderColor={{ from: "serieColor" }}
                // chartData={chartData}
              />
            </Grid>
            <Box>
              <Modal
                open={isHumidityModalOpen}
                onClose={toggleHumidityModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                    backdropFilter: "blur(2px)",
                  },
                }}
              >
                <Box sx={modalStyle}>
                  <Linetest
                    selectedDate={selectedDate}
                    dayType={dayType}
                    metric="rh"
                    margins={{ top: 80, right: 0, bottom: 70, left: 50 }}
                    actualData={data.getData}
                    predictedData={data.predictLoad}
                    legend="Humidity"
                    color1={[colors.linechart[300]]}
                    pointColor=" rgba(0,0,0,0)"
                    pointFaceColor="rgba(255,255,255)"
                    pointSize={2}
                    lineWidth={1}
                    pointWidth={1}
                    pointBorderColor={{ from: "serieColor" }}
                    // chartData={chartData}
                  />
                  <IconButton
                    onClick={toggleHumidityModal}
                    sx={{
                      position: "absolute",
                      top: "0.625rem",
                      right: "0.625rem",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Modal>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          marginTop: "1.875rem",
        }}
      >
        <Grid
          container
          columnSpacing={2.2}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ width: "100%" }}
        >
          <Grid item xs={12} sm={12} md={4} lg={2.4}>
            <Box
              sx={{
                backgroundColor: colors.grey[450],
                borderRadius: 7,
                height: "6.25rem",
                // width: "14.063rem",
                // mr: "1.125rem",
                display: "flex",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[200]}`,
              }}
            >
              <Grid
                xs={2}
                sm={2}
                md={2}
                lg={2}
                sx={{ pt: "1.25rem", pl: "1.25rem" }}
              >
                <FlashOnIcon
                  sx={{
                    backgroundColor: colors.primary.black,
                    color: colors.primary.main,
                    padding: "0.313rem",
                    width: "2rem",
                    height: "2rem",
                    borderRadius: "8px",
                  }}
                />
              </Grid>
              <Grid
                xs={9}
                sm={9}
                md={9}
                lg={9}
                sx={{ marginLeft: "2rem", marginTop: "1.25rem" }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
                >
                  {mape !== null ? mape : "N/A"} %
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.938rem",
                    marginTop: "0.625rem",
                    flex: "none",
                  }}
                >
                  Load MAPE
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2.4}>
            <Box
              sx={{
                backgroundColor: colors.orange[100],
                borderRadius: 7,
                height: "6.25rem",
                // width: "14.063rem",
                // mr: "1.125rem",
                display: "flex",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[200]}`,
              }}
            >
              <Grid
                xs={2}
                sm={2}
                md={2}
                lg={2}
                sx={{ pt: "1.25rem", pl: "1.25rem" }}
              >
                <Thermostat
                  sx={{
                    backgroundColor: colors.primary.black,
                    color: colors.primary.main,
                    padding: "0.313rem",
                    borderRadius: "8px",
                    width: "2rem",
                    height: "2rem",
                  }}
                />
              </Grid>
              <Grid
                xs={9}
                sm={9}
                md={9}
                lg={9}
                sx={{ marginLeft: "2rem", marginTop: "1.25rem" }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
                >
                  {Number(maeData.mae_temp !== null ? maeData.mae_temp : 0)
                    .toFixed(2)
                    .toString()}{" "}
                  %
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.938rem",
                    marginTop: "0.15rem",
                    flex: "none",
                    marginLeft: "none",
                  }}
                >
                  Temperature
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.938rem",
                    flex: "none",
                    marginLeft: "0.2rem",
                  }}
                >
                  MAPE
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2.4}>
            <Box
              sx={{
                backgroundColor: colors.purple[200],
                borderRadius: 7,
                height: "6.25rem",
                // width: "14.063rem",
                // mr: "1.125rem",
                display: "flex",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[200]}`,
              }}
            >
              <Grid
                xs={2}
                sm={2}
                md={2}
                lg={2}
                sx={{ pt: "1.25rem", pl: "1.25rem" }}
              >
                <WaterDropIcon
                  sx={{
                    backgroundColor: colors.primary.black,
                    color: colors.primary.main,
                    padding: "0.313rem",
                    borderRadius: "8px",
                    width: "2rem",
                    height: "2rem",
                  }}
                />
              </Grid>
              <Grid
                xs={9}
                sm={9}
                md={9}
                lg={9}
                sx={{ marginLeft: "2rem", marginTop: "1.25rem" }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
                >
                  {Number(maeData !== null ? maeData.mae_rh : "N/A")
                    .toFixed(2)
                    .toString()}{" "}
                  %
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.938rem",
                    marginTop: "0.625rem",
                    flex: "none",
                  }}
                >
                  Humidity MAPE
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2.4}>
            <Box
              sx={{
                backgroundColor: colors.red[100],
                borderRadius: 7,
                height: "6.25rem",
                // width: "14.063rem",
                // mr: "1.125rem",
                display: "flex",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[200]}`,
              }}
            >
              <Grid
                xs={2}
                sm={2}
                md={2}
                lg={2}
                sx={{ pt: "1.25rem", pl: "1.25rem" }}
              >
                <FlashOnIcon
                  sx={{
                    backgroundColor: colors.primary.black,
                    color: colors.primary.main,
                    padding: "0.313rem",
                    borderRadius: "8px",
                    width: "2rem",
                    height: "2rem",
                  }}
                />
              </Grid>
              <Grid
                xs={9}
                sm={9}
                md={9}
                lg={9}
                sx={{ marginLeft: "2rem", marginTop: "1.25rem" }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
                >
                  {maxError !== null ? maxError.toFixed(2) : "N/A"} MW
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.938rem",
                    marginTop: "0.625rem",
                    flex: "none",
                  }}
                >
                  Max Error
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2.4}>
            <Box
              sx={{
                backgroundColor: colors.red[100],
                borderRadius: 7,
                height: "6.25rem",
                // width: "14.063rem",
                // mr: "1.125rem",
                display: "flex",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[200]}`,
              }}
            >
              <Grid
                xs={2}
                sm={2}
                md={2}
                lg={2}
                sx={{ pt: "1.25rem", pl: "1.25rem" }}
              >
                <FlashOnIcon
                  sx={{
                    backgroundColor: colors.primary.black,
                    color: colors.primary.main,
                    padding: "0.313rem",
                    borderRadius: "8px",
                    width: "2rem",
                    height: "2rem",
                  }}
                />
              </Grid>
              <Grid
                xs={9}
                sm={9}
                md={9}
                lg={9}
                sx={{ marginLeft: "2rem", marginTop: "1.25rem" }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
                >
                  {minError !== null ? minError.toFixed(2) : "N/A"} MW
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.938rem",
                    marginTop: "0.625rem",
                    flex: "none",
                  }}
                >
                  Min Error
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{}}>
        <PredictTable
          selectedDate={selectedDate}
          dayType={dayType}
          onAverageValuesChange={handleAverageValuesChange}
        />
      </Box>
    </div>
  );
}
export default IntraDay;
